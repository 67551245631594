var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('focus-area-single',[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"mt-3"},[_c('v-progress-circular',{staticClass:"mx-auto",attrs:{"indeterminate":""}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}]},[_c('v-card',{staticClass:"pa-3"},[_c('v-row',[_c('v-btn',{staticClass:"ml-2 mt-2",attrs:{"icon":""},on:{"click":_vm.navigateBack}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-img',{attrs:{"src":_vm.coverImgSrc}})],1),_c('v-col',{attrs:{"cols":"9"}},[_c('p',{staticClass:"text-h4"},[_vm._v(_vm._s(_vm.novelTitle))]),_c('p',{staticClass:"body-1",domProps:{"innerHTML":_vm._s(_vm.novelIntro)}})])],1),_c('v-row',{staticClass:"mx-2 mb-1"},[_vm._l((_vm.tagArr),function(item,index){return _c('v-chip',{key:index,staticClass:"mx-1",attrs:{"outlined":"","label":"","color":"green"}},[_vm._v("标签 | "+_vm._s(item)+" ")])}),_c('v-spacer'),_c('v-chip',{attrs:{"outlined":"","label":"","color":"blue"}},[_vm._v("字数 | "+_vm._s(_vm.novelWordcount))])],2),_c('v-card-actions',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-download")]),_vm._v(" 下载 ")],1)]}}])},[_c('v-list',_vm._l((_vm.downloadSelector),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',[_c('a',{attrs:{"target":"_blank","href":_vm.$config.baseUrl+'novel/download/'+_vm.novelId+'?'+item.queryUri}},[_vm._v(" "+_vm._s(item.text)+" ")])])],1)}),1)],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"text":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")]),_vm._v(" 预览 ")],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.navigateToRead('normal')}}},[_c('v-list-item-title',[_vm._v("Normal模式")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.navigateToRead('fallback')}}},[_c('v-list-item-title',[_vm._v("Fallback模式")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.adminKey.length),expression:"adminKey.length"}],staticClass:"ml-2",attrs:{"text":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-tag-multiple")]),_vm._v(" 标签 ")],1)]}}]),model:{value:(_vm.updateTagsDialog),callback:function ($$v) {_vm.updateTagsDialog=$$v},expression:"updateTagsDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("更改标签")]),_c('v-container',[_c('v-text-field',{attrs:{"label":"标签（英文逗号隔开）"},model:{value:(_vm.inputTags),callback:function ($$v) {_vm.inputTags=$$v},expression:"inputTags"}}),_c('v-subheader',[_vm._v("所有标签")]),_vm._l((_vm.allTagArr),function(item,index){return _c('v-chip',{key:'at-'+index,staticClass:"ml-1 mt-1",on:{"click":function($event){return _vm.addTagToInputTags(item.name)}}},[_c('v-icon',[_vm._v("mdi-tag")]),_vm._v(" "+_vm._s(item.name)+" | "+_vm._s(item.count)+" ")],1)})],2),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.updateTagsDialog=false}}},[_vm._v("取消")]),_c('v-btn',{attrs:{"text":"","loading":_vm.updateTagsLoading},on:{"click":_vm.updateTags}},[_vm._v("确认")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.adminKey.length),expression:"adminKey.length"}],staticClass:"ml-2",attrs:{"text":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-regex")]),_vm._v(" 断章 ")],1)]}}]),model:{value:(_vm.updateSignifierDialog),callback:function ($$v) {_vm.updateSignifierDialog=$$v},expression:"updateSignifierDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("更改断章正则")]),_c('v-container',[_c('v-text-field',{attrs:{"label":"用于标题的正则表达式","rules":[_vm.validateRegexp]},model:{value:(_vm.inputSignifier),callback:function ($$v) {_vm.inputSignifier=$$v},expression:"inputSignifier"}}),_c('v-subheader',[_vm._v("参考正则")]),_c('code',[_vm._v("[1-9一二三四五六七八九十]+(章|回|幕|话|节|\\.|、|:|：|，| )+")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.updateSignifierDialog=false}}},[_vm._v("取消")]),_c('v-btn',{attrs:{"text":"","loading":_vm.updateSignifierLoading},on:{"click":_vm.updateSignifier}},[_vm._v("确认")])],1)],1)],1)],1)],1),_c('novel-toc-table',{attrs:{"toc":_vm.toc,"updatePage":_vm.$vuetify.goTo(0),"novelId":+_vm.novelId}})],1),_c('back-to-top-fab')],1)}
var staticRenderFns = []

export { render, staticRenderFns }